import CustomTextInput from "../components/generic/CustomTextInput";
import GoogleAddressSearch from "../components/GoogleAddressSearch";
import React, {useState} from "react";
import logo from '../assets/logo-menus.webp';
import CustomButton from "../components/CustomButton";
import {isMobile, isTablet} from "react-device-detect";
import {showSuccessNotification} from "../utils/NotificationUtils";

export default function HomeFormPage() {
    const [conferma, setConferma] = useState(false)
    const [messageSuccess, setMessageSuccess] = useState(false)
    const [formData, setFormData] = useState({
        nome: '',
        cognome: '',
        indirizzo: '',
        email: '',
        telefono: '',
        timeBand: '',
        denominazione: '',
        tipoAttivita: '',
        note: '',
        referente: 'WEB'
    });

    const handleChange = (field: string) => (value: string) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleAddressSelect = (place: google.maps.places.PlaceResult) => {
        setFormData(prev => ({
            ...prev,
            indirizzo: place.formatted_address || ''
        }));
    };

    setTimeout(() => {
        setMessageSuccess(false)
    }, 2000)


    if (isMobile) {
        return (
            <div className={`w-full h-full flex bg-black items-center justify-center`}>
                <div className={`w-full h-full flex flex-col items-center justify-center bg-black`}>

                    <img className={`w-[45%] h-full py-5`} src={logo} alt="Logo"/>

                    <div className="w-full transition-all duration-700 hover:scale-[1.02] relative"
                         style={{animation: 'slideUp 1s ease-out 0.8s both'}}>
                        <div className="absolute inset-0 opacity-20  z-10"/>
                        <video
                            src="https://storage.googleapis.com/maisazio/video_home_maisazio/video-pre-ordine.mov"
                            autoPlay
                            loop
                            muted
                            playsInline
                            className="w-full h-full object-cover "
                        />
                    </div>

                    <p className={`font-poppins-regular text-white pt-5`}>
                        Benvenuto su <span className={'text-[#FFCC00]'}>MaiSazio</span>!
                    </p>
                    <p className={`font-poppins-regular text-white`}>Il futuro della ristorazione, OGGI!</p>

                    <div className={`${isTablet ? "w-[400px] h-auto space-y-4 mt-5 px-10":"w-full h-auto space-y-4 mt-5 px-10"}`}>
                        <CustomTextInput
                            white={true}
                            isBlack={true}
                            defaultValue={formData.nome}
                            customDimensions={'w-full h-[40px]  '}
                            placeholder={"Nome"}
                            onChange={handleChange('nome')}

                        />
                        <CustomTextInput
                            white={true}
                            isBlack={true}
                            defaultValue={formData.cognome}
                            customDimensions={'w-full h-[40px]'}
                            placeholder={"Cognome"}
                            onChange={handleChange('cognome')}
                        />
                        <CustomTextInput
                            white={true}
                            isBlack={true}
                            customDimensions={'w-full h-[40px]'}
                            placeholder={"Indirizzo"}
                            defaultValue={formData.indirizzo}
                            onChange={handleChange('indirizzo')}
                        />
                        <CustomTextInput
                            isBlack={true}
                            white={true}
                            customDimensions={'w-full h-[40px]'}
                            placeholder={"Email"}
                            defaultValue={formData.email}
                            onChange={handleChange('email')}
                        />
                        <CustomTextInput
                            white={true}
                            isBlack={true}
                            customDimensions={'w-full h-[40px]'}
                            placeholder={"Numero di telefono"}
                            defaultValue={formData.telefono}
                            onChange={handleChange('telefono')}
                        />
                        <CustomTextInput
                            white={true}
                            isBlack={true}
                            customDimensions={'w-full h-[40px]'}
                            placeholder={"Quando desideri essere contattato ?"}
                            defaultValue={formData.timeBand}
                            onChange={handleChange('timeBand')}
                        />
                        <CustomTextInput
                            white={true}
                            isBlack={true}
                            customDimensions={'w-full h-[40px]'}
                            placeholder={"Nome Attivita`"}
                            defaultValue={formData.denominazione}
                            onChange={handleChange('denominazione')}
                        />
                        <CustomTextInput
                            white={true}
                            isBlack={true}
                            customDimensions={'w-full h-[40px]'}
                            placeholder={"Tipo di  Attivita`"}
                            defaultValue={formData.tipoAttivita}
                            onChange={handleChange('tipoAttivita')}
                        />
                        <CustomTextInput
                            white={true}
                            isBlack={true}
                            customDimensions={'w-full h-[40px]'}
                            placeholder={"Note"}
                            defaultValue={formData.note}
                            onChange={handleChange('note')}
                        />
                    </div>

                    <div className={`py-5`}>
                        <CustomButton
                            customStyles="text-black px-5 mt-5"
                            bgColor="#FFCC00"
                            title={conferma ? "Loading" : "Invia"}
                            onClick={() => {
                                setConferma(true)
                                fetch('https://maisazio-service.gicod.it/users/user/sigep', {
                                    method: 'PUT',
                                    headers: {
                                        'Accept': '*/*',
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify(formData),
                                })
                                    .then((response) => {
                                        if (!response.ok) {
                                            throw new Error(`HTTP error! status: ${response.status}`);
                                        }
                                        setConferma(false)
                                        setMessageSuccess(true)
                                        setFormData({
                                            referente: formData.referente,
                                            nome: '',
                                            cognome: '',
                                            email: '',
                                            telefono: '',
                                            denominazione: '',
                                            indirizzo: '',
                                            timeBand: '',
                                            note: '',
                                            tipoAttivita: ''
                                        })
                                        return response.json();
                                    })
                                    .then((responseData) => {
                                        setConferma(false)
                                        console.log('Success:', responseData);
                                    })
                                    .catch((error) => {
                                        setConferma(false)
                                        console.error('Error:', error);
                                    });

                            }}
                            enable={true}
                            isLoading={false}
                        />
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className={`w-full min-h-screen flex bg-black`}>
                <div className={`w-[60%] h-auto flex items-center justify-center`}>
                    <div className="w-full transition-all duration-700 hover:scale-[1.02] relative"
                         style={{animation: 'slideUp 1s ease-out 0.8s both'}}>
                        <div className="absolute inset-0 opacity-20  z-10"/>
                        <video
                            src="https://storage.googleapis.com/maisazio/video_home_maisazio/video-pre-ordine.mov"
                            autoPlay
                            loop
                            muted
                            playsInline
                            className="w-full h-full object-cover "
                        />
                    </div>

                </div>


                <div className={`w-[40%]  flex flex-col items-center justify-center p-10 `}>
                    <div className={`w-full h-full flex flex-col items-center justify-center mt-10`}>
                        <img className={`w-[40%] h-auto`} src={logo} alt="Logo"/>
                        <p className={`font-poppins-regular text-white`}>
                            Benvenuto su <span className={'text-[#FFCC00]'}>MaiSazio</span>!
                        </p>
                        <p className={`font-poppins-regular text-white`}>Il futuro della ristorazione, OGGI!</p>
                    </div>


                    <div className={`w-full space-y-4 mt-5 px-10`}>
                        <CustomTextInput
                            white={true}
                            isBlack={true}
                            defaultValue={formData.nome}
                            customDimensions={'w-full h-[40px]  '}
                            placeholder={"Nome"}
                            onChange={handleChange('nome')}

                        />
                        <CustomTextInput
                            white={true}
                            isBlack={true}
                            defaultValue={formData.cognome}
                            customDimensions={'w-full h-[40px]'}
                            placeholder={"Cognome"}
                            onChange={handleChange('cognome')}
                        />
                        <CustomTextInput
                            white={true}
                            isBlack={true}
                            customDimensions={'w-full h-[40px]'}
                            placeholder={"Indirizzo"}
                            defaultValue={formData.indirizzo}
                            onChange={handleChange('indirizzo')}
                        />
                        <CustomTextInput
                            isBlack={true}
                            white={true}
                            customDimensions={'w-full h-[40px]'}
                            placeholder={"E-mail"}
                            defaultValue={formData.email}
                            onChange={handleChange('email')}
                        />
                        <CustomTextInput
                            white={true}
                            isBlack={true}
                            customDimensions={'w-full h-[40px]'}
                            placeholder={"Numero di telefono"}
                            defaultValue={formData.telefono}
                            onChange={handleChange('telefono')}
                        />
                        <CustomTextInput
                            white={true}
                            isBlack={true}
                            customDimensions={'w-full h-[40px]'}
                            placeholder={"Quando desideri essere contattato?"}
                            defaultValue={formData.timeBand}
                            onChange={handleChange('timeBand')}
                        />
                        <CustomTextInput
                            white={true}
                            isBlack={true}
                            customDimensions={'w-full h-[40px]'}
                            placeholder={"Nome Attivita`"}
                            defaultValue={formData.denominazione}
                            onChange={handleChange('denominazione')}
                        />
                        <CustomTextInput
                            white={true}
                            isBlack={true}
                            customDimensions={'w-full h-[40px]'}
                            placeholder={"Tipo di Attivita`"}
                            defaultValue={formData.tipoAttivita}
                            onChange={handleChange('tipoAttivita')}
                        />
                        <CustomTextInput
                            white={true}
                            isBlack={true}
                            customDimensions={'w-full h-[40px]'}
                            placeholder={"Note"}
                            defaultValue={formData.note}
                            onChange={handleChange('note')}
                        />
                    </div>

                    <div className={`w-auto h-full mt-5`}>
                        <CustomButton
                            customStyles="text-black px-20 "
                            bgColor="#FFCC00"
                            title={conferma ? "Loading" : "Invia"}
                            onClick={() => {
                                setConferma(true)
                                fetch('https://maisazio-service.gicod.it/users/user/sigep', {
                                    method: 'PUT',
                                    headers: {
                                        'Accept': '*/*',
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify(formData),
                                })
                                    .then((response) => {
                                        if (!response.ok) {
                                            throw new Error(`HTTP error! status: ${response.status}`);
                                        }
                                        setConferma(false)
                                        setMessageSuccess(true)
                                        setFormData({
                                            referente: formData.referente,
                                            nome: '',
                                            cognome: '',
                                            email: '',
                                            telefono: '',
                                            denominazione: '',
                                            indirizzo: '',
                                            timeBand: '',
                                            note: '',
                                            tipoAttivita: ''
                                        })
                                        return response.json();
                                    })
                                    .then((responseData) => {
                                        setConferma(false)
                                        showSuccessNotification('Grazie!','Il nostro team ti contatterà per qualsiasi informazione',3000)
                                        console.log('Success:', responseData);
                                    })
                                    .catch((error) => {
                                        setConferma(false)
                                        console.error('Error:', error);
                                    });

                            }}
                            enable={true}
                            isLoading={false}
                        />
                    </div>

                    <div className="w-full h-full flex items-center justify-center relative overflow-hidden mt-4 ">
                        <div
                            className="absolute inset-0 bg-gradient-to-r from-black via-transparent to-black pointer-events-none"></div>
                        <div
                            className="whitespace-nowrap overflow-hidden animate-scroll text-white font-poppins-regular  pt-12">
                            2 stampanti incluse - Assistenza - Categorie e voci illimitate - Comanda direttamente da
                            smartphone - Esporta Menù in PDF - Foto attività, menù, categorie, piatti personalizzabili -
                            Geolocalizzazione Attività - Gestione comande - Gestione cucine - Gestione personale -
                            Gestione
                            Prenotazioni Tavoli - Gestione Tavoli - Informazioni attività - Invia preconto al tavolo -
                            Multi-Menù personalizzabile - Prodotti modificabili dal cliente - QR-CODE Menu - Recensioni
                            e
                            punteggio - Sincronizzazione Cucine - Stampa autamatica comanda - Stampa preconto -
                            Statistiche
                            attività
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
