import {TextInput, PasswordInput} from '@mantine/core';
import passwordVisible from '../../assets/passwordViss.svg'
import passwordHide from '../../assets/passordHide.svg'
import {useEffect, useState} from "react";

interface CustomTextInputProps {
    customDimensions?: string;
    isPassword?: boolean;
    defaultValue?: string;
    label?: string;
    labelColor?: string; // Prop opzionale per il colore della label
    white?: boolean;
    placeholder?: string;
    description?: string;
    readOnly?: boolean;
    required?: boolean;
    maxLength?: number;
    onChange?: (text: string) => void;
    isBlack?: boolean;
    onEnterPress?: () => void;
}

export default function CustomTextInput({
                                            customDimensions = '',
                                            isPassword = false,
                                            defaultValue = '',
                                            label = '',
                                            white = false,
                                            placeholder = '',
                                            description = '',
                                            readOnly = false,
                                            required = false,
                                            maxLength,
                                            onChange = () => {},
                                            isBlack = false,
                                            onEnterPress = () => {},
                                        }: CustomTextInputProps) {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const handleInputChange = (newValue: string) => {
        setValue(newValue);
        onChange(newValue);
    };

    return (
        <div
            className={`rounded-md ${customDimensions || 'w-full'} ${isBlack ? 'bg-gray-800 text-white' : ''}`}
        >
            {isPassword ? (
                <PasswordInput
                    label={label}
                    value={value}
                    visible={passwordVisible}
                    placeholder={placeholder}
                    description={description}
                    maxLength={maxLength}
                    readOnly={readOnly}
                    required={required}
                    classNames={{
                        input: 'inputStyle',
                        wrapper: !isBlack?'wrapperStyleWhite':'wrapperStyle'
                    }}
                    onKeyPress={(e) => {
                        if (e.code === 'Enter') {
                            onEnterPress();
                        }
                    }}
                    onChange={(e) => handleInputChange(e.currentTarget.value)}
                />
            ) : (
                <TextInput
                    label={label}
                    value={value}
                    placeholder={placeholder}
                    description={description}
                    maxLength={maxLength}
                    readOnly={readOnly}
                    required={required}
                    classNames={{
                        input: 'inputStyle',
                        wrapper: !isBlack?'wrapperStyleWhite':'wrapperStyle'
                    }}
                    onKeyPress={(e) => {
                        if (e.code === 'Enter') {
                            onEnterPress();
                        }
                    }}
                    onChange={(e) => handleInputChange(e.currentTarget.value)}
                />
            )}
        </div>
    );
}
